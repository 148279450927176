
import { Component, Vue } from "vue-property-decorator";
import Header from "@/components/header.vue"; // @ is an alias to /src
// import SidebarReports from "@/components/sidebar-reports.vue";
import Loader from "@/components/loader.vue"; // @ is an alias to /src
import TreeTable from "@/components/trialtable/TreeTable.vue";
// import HRMService from "../../../services/hrm-service";
import accountService from "@/services/account-service";
import FilterComponent from "@/components/sidebar-employee-filter.vue"
import FilterService from "@/services/filter-service"
@Component({
    components: {
        Header,
        Loader,
        // SidebarReports,
        TreeTable,
        FilterComponent,
    },
})
export default class IncomeStatement extends Vue {
    private data: any[] = [];
    public filter = null;
    public filterOn = [];
    loading = true;
    public currentItem = null;
    public currentIndex = -1;
    public currentPage = 1;
    public title = "";
    trans: any = [];
    selected_branch: any = {};
    public options_branch: any = [];
    public options_division: any = [];
    tableData: any = [];
    total: any = {};
    flattenedData: any = [];
    widths = [100, 200, 90, 90,]
    columns = [
        {
            label: "Code",
            id: "code",
        },
        {
            label: "Account",
            id: "account_title",
        },
        // {
        //   label: "Opening Balance Debit",
        //   id: "opening_balance_debit",
        // },
        // {
        //   label: "Opening Balance Credit",
        //   id: "opening_balance_credit",
        // },
        {
            label: "Transaction Debit",
            id: "transaction_debit",
        },
        {
            label: "Transaction Credit",
            id: "transaction_credit",
        },
        // {
        //     label: "Debit Close",
        //     id: "debit_close",
        // },
        // {
        //     label: "Credit Close",
        //     id: "credit_close",
        // },
    ];
    columnsData = [
        {
            label: "Code",
            field: "code",
        },
        {
            label: "Account",
            field: "account_title",
        },
        // {
        //     label: "Opening Balance Debit",
        //     field: "opening_balance_debit",
        // },
        // {
        //     label: "Opening Balance Credit",
        //     field: "opening_balance_credit",
        // },
        {
            label: "Transaction Debit",
            field: "transaction_debit",
        },
        {
            label: "Transaction Credit",
            field: "transaction_credit",
        },
        // {
        //     label: "Debit Close",
        //     field: "debit_close",
        // },
        // {
        //     label: "Credit Close",
        //     field: "credit_close",
        // },
    ];
    retreive() {
        let obj = {
            "start_account_title": "",
            "end_account_title": "",
            "start_code": "04",
            "end_code": "05",
            "level_range_start": "",
            "level_range_end": ""
        };
        FilterService.getTrialBalance(obj).then((res) => {
            if (this.tableData.length === 0) {
                // console.log("no");
            }
            this.loading = true;
            this.tableData = res.data.data;
            this.total = res.data.total;
            this.flattenData(this.tableData);
            if (this.tableData.length > 0) {
            }
            this.loading = false;
        })
    }
    mounted() {
        this.retreive();
    }
    flattenData(data: any, parentCode = null, level = 0) {
        for (const item of data) {
            // Add flattened item to the export data
            this.flattenedData.push({
                code: item.code,
                account_title: item.account_title,
                opening_balance_debit: item.opening_balance_debit,
                opening_balance_credit: item.opening_balance_credit,
                transaction_debit: item.transaction_debit,
                transaction_credit: item.transaction_credit,
                debit_close: item.debit_close,
                credit_close: item.credit_close,
            });

            // Recursively process children
            if (item.children && item.children.length > 0) {
                this.flattenData(item.children, item.code, level + 1);
            }
        }
    }
}
